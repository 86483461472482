:root {
    --header-height: 19rem; /* Fallback value */
}

body{
	font-family: Garamond, serif;
	background-color:#f5f5f3;
}
#dheader{
	background-color:#f5f5f3;
}
#dheader1{
	background-color:#f5f5f3;
}
.btn-get-started {
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 8px 28px;
    border-radius: 3px;
    transition: 0.5s;
    margin: 10px;
    color: #f4f0e8 !important;
    background: black;
}
.weather{
	letter-spacing:0.2px;
	font-size:1.05rem;
}
.news-types {
            list-style-type: none; /* Remove bullets */
            padding: 0; /* Remove default padding */
            margin: 0; /* Remove default margin */
            display: flex; /* Display items in a row */
            justify-content: space-around; /* Space items evenly */
}
.news-types li {
  padding-left:0.4rem; /* Add some padding */
}
.news-types li a,.ct {
   text-decoration: none; /* Remove underline */
   color: #000; /* Set text color */
   font-size:1.2rem;
   font-weight:bold;
   letter-spacing:0.2px;
}
.logoclass{
	text-align:center;
}
.datedis{
	text-align:center;
}
@media screen and (max-width: 768px) {
          .logoclass{
	text-align:end;
}

.datedis{
	text-align:start;
}
        }
		
@media screen and (max-width: 887px) {
            .news-types li a{
                font-size:1rem; /* Reduce font size for smaller screens */
            }
        }
		
.paper{
	padding-top: var(--header-height);
	padding-bottom:4rem;
}
.dboard{
	padding-top: var(--header-height);
	padding-bottom:4rem;
}
.newsview{
	padding-top:12rem;
	padding-bottom:4rem;
}
.loginp{
		padding-top:3rem;
}
.news{
	border:1px solid #b7b5b5;
}
.onlynew{
	border-top:1px solid #b7b5b5;
}
.newhead{
	font-size:1.05rem;
	
   letter-spacing:0.4px;
}
.newtag{
	font-size:1.2rem;
   font-weight:bold;
   letter-spacing:0.8px;
   background-color:#f4f0e8;
  border:1px solid #b7b5b5;
}
.trending{
   font-size:1.1rem;
   font-weight:bold;
   letter-spacing:0.5px;
   background-color:#f4f0e8;
  border:1px solid #b7b5b5;
}
.pagination li{
	background-color:#f4f0e8!important;
}
.circle {
    display: inline-block;
    width: 3rem; /* Fixed width */
    height: 3rem; /* Fixed height */
    line-height: 3rem; /* Center text vertically */
    border-radius: 50%; /* Make it a circle */
    background-color: black; /* Background color */
    color: white; /* Text color */
    text-align: center; /* Center text horizontally */
    font-size: 1.2rem; /* Font size */
	align-items:center;
}
.form_row_full {
    width: 100%;
    float: left;
    clear: both;
    padding: 0;
    margin: 0 0 25px 0;
}
textarea.form_textarea_full {
    width: 98%;
    height: 140px;
   background-color:#f4f0e8;
    border: 1px solid #b7b5b5;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -khtml-border-radius: 8px;
    float: left;
    padding: 13px 1% 0 1%;
    font-size: 14px;
    letter-spacing: 0.2px;
}
textarea.form_textarea_full2 {
    width: 98%;
    height: 100px;
   background-color:#f4f0e8;
    border: 1px solid #b7b5b5;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -khtml-border-radius: 4px;
    float: left;
    padding: 13px 1% 0 1%;
    font-size: 14px;
    letter-spacing: 0.2px;
}
.form-title {
    line-height: 1.66;
    margin: 0;
    padding: 0;
    font-weight: 700;
    color: #222;
    font-size: 33px;
}
.loginp div div{
	background-color:#f6f4f1
}
  .horizontal-scroll {
            overflow-x: auto;
            white-space: nowrap;
            padding-bottom: 5px; /* Optional: Add padding to prevent content from hiding behind the scrollbar */
        }
        .horizontal-scroll ul {
            display: flex;
            padding: 0;
            margin: 0;
            list-style: none;
        }
        .horizontal-scroll li {
            flex: 0 0 auto; /* Prevent flex items from growing or shrinking */
          
        }
        .horizontal-scroll a {
            text-decoration: none;
            color: inherit;
            display: block;
            padding: 10px;
        }
.reset-btn{
	background-color:black;
	color:white;
	padding:0.5rem 2rem;
	font-size:1rem;
	letter-spacing:0.1rem
}
.form_row_full {
  display: flex;
  align-items: center; /* Vertically centers the items */
  gap: 10px; /* Adds space between textarea and button */
}

.form_textarea_full {
  border-radius:0!important;
  flex: 1; /* Makes textarea take up remaining space */
  margin-right: 10px; /* Adds space between textarea and button */
  resize: none; /* Optional: Prevents textarea from being resized */
  height: 40px; /* Set height to make it align nicely with the button */
}

.news-types li a.selected {
  color: white;
  background-color: black!important;
}

.sidebar {
  position: fixed;
  top: 0;
  right: -100%;
  width: 250px;
  height: 100%;
  background-color: #333;
  color: white;
  padding: 20px;
  transition: right 0.3s ease;
  z-index: 1000;
}

.sidebar.open {
  right: 0;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar ul li {
  margin: 20px 0;
}

.sidebar ul li a {
  color: white;
  text-decoration: none;
}

.sidebar .close-btn {
  font-size: 1.5rem;
  background: none;
  border: none;
  color: white;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

/* Adjust sidebar visibility */
@media (max-width: 768px) {
  .sidebar {
    width: 70%;
  }
}